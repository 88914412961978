<script setup>
import {defineProps, ref, onMounted, defineEmits} from 'vue'
import TheModal from '@/components/ui/TheModal.vue'
import BaseForm from '@/components/base/BaseForm.vue'
import {useOnboardingStore} from '@/views/onboarding/OnboardingStore'
import AlertInfo from '@/components/ui/alerts/AlertInfo.vue'

const {setClientClickupTask} = useOnboardingStore()

// Props & emits
const props = defineProps({
  id: {
    type: String,
    default: null,
  },
})
const emit = defineEmits(['submitted', 'hidden'])
const modalId = props.id ?? 'onboarding-compliance-check-clickup-modal'

// Refs
const theModal = ref()
const clickup_task_url = ref('')

// Methods
onMounted(() => {
  openModal()
})

const openModal = () => {
  theModal.value.handleOpenModal()
}

const closeModal = () => {
  theModal.value.handleCloseModal()
}
const handleSubmit = () => {
  setClientClickupTask(clickup_task_url.value)

  emit('submitted')
  closeModal()
}

const emitHidden = () => {
  emit('hidden')
}
</script>

<template>
  <TheModal ref="theModal" @hidden="emitHidden" :id="modalId" title="Сompliance Сheck">
    <base-form @on-submitted="handleSubmit">
      <div class="modal-body">
        <div class="form-group">
          <label>Link to Client Pre-Moderation ClickUp Task *</label>
          <input v-model="clickup_task_url" class="form-control" type="url" required />
        </div>
        <div class="form-group">
          <alert-info>
            Customer compliance verification is mandatory.

            <div class="mt-2">
              Add a link to the ClickUp task from the
              <a
                href="https://app.clickup.com/4620077/v/b/6-901201998197-2"
                target="_blank"
                rel="nofollow noopener noreferrer"
                >Agency General</a
              >
              project.
            </div>

            <div class="mt-2">
              If the client isn't reviewed by Compliance, submit a request in
              <a href="/ad-account-requests/agency-general" target="_blank" rel="nofollow noopener noreferrer"
                >Client Pre-Moderation</a
              >.
            </div>
          </alert-info>
        </div>
      </div>
      <div class="modal-footer justify-content-end custom gap-4">
        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
        <button class="btn btn-primary">Submit</button>
      </div>
    </base-form>
  </TheModal>
</template>
