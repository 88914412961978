<script setup>
import {nextTick, ref, watch} from 'vue'
import {useRoute} from 'vue-router'

const route = useRoute()
const showDropdownMenu = ref(false)

// Methods
const toggleDropdownMenu = () => {
  showDropdownMenu.value = !showDropdownMenu.value
}

watch(
  () => route.path,
  async () => {
    await nextTick()
    const menuLinks = document.querySelectorAll('.nav-dropdown-menu-item')
    showDropdownMenu.value = Array.from(menuLinks).some(link => link.classList.contains('active'))
  },
  {immediate: true},
)
</script>

<template>
  <div class="nav-dropdown">
    <button @click="toggleDropdownMenu" class="nav-dropdown-button btn w-100 text-start" type="button">
      <span class="d-flex align-items-center">
        <slot name="button"></slot>
      </span>
      <i :class="['fa-light fa-chevron-up dropdown-arrow custom fs-5', !showDropdownMenu ? 'fa-rotate-180' : '']"></i>
    </button>
    <ul v-show="showDropdownMenu" class="nav-dropdown-menu">
      <slot name="menu"></slot>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.dropdown-arrow {
  font-size: 20px;
  transition: all linear 0.1s;
  color: #6b7280;
}
</style>
