import {createApp} from 'vue'
import {createPinia} from 'pinia'
import {useAuthenticationStore, usePointBoardStore} from './stores'
import Notifications from '@kyvg/vue3-notification'
import '@fortawesome/fontawesome-free/js/all.js'
import '@fortawesome/fontawesome-pro/css/all.min.css'
import 'clipboard'
import App from './App.vue'
import router from './router/routes'
import 'bootstrap'
import sentryInstance from '@/plugins/sentry'
import FloatingVue from 'floating-vue'
import {register} from 'swiper/element/bundle'

const pinia = createPinia()
const app = createApp(App)

register()
sentryInstance

app.use(pinia)
app.use(router)
app.use(Notifications)
app.use(FloatingVue)
app.mount('#app')

export const authenticationStore = useAuthenticationStore()
export const pointBoardStore = usePointBoardStore()
export const mainRouter = router
