export const accessRules = {
  hasAccessToDashboard: auth => (auth.isAgencyClient && auth.authUser.subrole === 'admin') || auth.isMediaBuyer,
  hasAccessToUserSettings: auth => auth.isCompanyAdmin,
  hasAccessToMyRequests: auth => auth.userHasCompany,
  hasAccessToCreativeModerationRequest(auth) {
    return auth.isComplianceManager || auth.isAgency || auth.isDeveloper || auth.isAlexKosovskiy
  },
  hasAccessToClientPreModerationList: auth =>
    auth.isComplianceManager || auth.isAgency || auth.isDeveloper || auth.isAlexKosovskiy,
  hasAccessToRequestsPage: auth => auth.hasAccessToRequestsPage,
  hasAccessToCreativeModerationList(auth) {
    return auth.isAgencyClient && !auth.isAgency && !auth.isComplianceManager
  },
  hasAccessToCreativeModerationProfile(auth) {
    return this.hasAccessToCreativeModerationList(auth) || this.hasAccessToCreativeModerationRequest(auth)
  },

  hasAccessToPaymentsDailyBalance: auth => auth.isAgencyClient,
  hasAccessToFinancePage: auth => auth.canUseInvoices,
  hasAccessToInvoices: auth => auth.isFinance,

  hasAccessToClientOnboarding: auth => auth.canWorkWithOnboardings,
  hasAccessToOnboardingDocument: auth => auth.canWorkWithOnboardings,
  hasAccessToCompaniesPage: auth => auth.hasAccessToCompaniesPage,
  hasAccessToEmployeeList: auth => auth.canAddUserToPointboard,

  hasAccessToFacebookPages: auth => auth.isTechDepartment || auth.isAgencyAssistance,
  hasAccessToAssetsPage: auth => auth.hasAccessToAssetsPage,
  hasAccessToAccountConnections: auth =>
    auth.isAgency || auth.isDeveloper || auth.isAnalyst || auth.isComplianceManager,

  hasAccessToTracker: auth => auth.isDeveloperOrInternalCompany && !auth.isGoogleUser,
  hasAccessToLandings: auth => auth.isDeveloper || auth.isMediaBuyer,
  hasAccessToAdAlerts: auth => auth.isDeveloper || auth.isMediaBuyer,
  hasAccessToFacebookLicense: auth => auth.isAgencyAssistance || auth.isDeveloper || auth.isAnalyst,
  hasAccessToBusinessManagers: auth => auth.isAgencyAssistance || auth.isDeveloper || auth.isAnalyst,

  hasAccessToLandingTask: auth => auth.canCreateNewLandingTask,
  hasAccessToLandingCopyTask: auth => auth.canCreateCopyLandingTask,
  hasAccessToABTestTask: auth => auth.canCreateABtestTask,
  hasAccessToCreativesTask: auth => auth.canCreateCreativeTask,
  hasAccessToShortTask: auth => auth.canCreateShortTask,
  hasAccessToSMMTask: auth => auth.canCreateSMMTask,
  hasAccessToHandsOnTask: auth => auth.isAgency,
  hasAccessToAgencyGeneralTask: auth => auth.canCreateAgencyGeneralTask,

  hasAccessToPaymentRequestTask: auth => auth.isPoint2WebEmployee,
  hasAccessToTasks: auth => auth.isPoint2WebEmployee,
  hasAccessToReports: auth => auth.isPoint2WebEmployee,
  hasAccessToCRDomain: auth => auth.canUseDomains && auth.fromSFCompany,
  hasAccessToDomains: auth => auth.canUseDomains,
  hasAccessToFacebookProfiles: auth => auth.canSeeFacebookPages,
  hasAccessToSFLinks: auth => auth.fromSFCompany || auth.isDeveloper,

  hasAccessToTools: auth => auth.isPoint2WebEmployee,
  hasAccessToPixels: auth => auth.isTechDepartment || auth.fromLGCompany,
  hasAccessToConversions: auth => auth.isTechDepartment || auth.fromLGCompany,
  hasAccessToClickupPage: auth => auth.isPoint2WebEmployee,
  hasAccessToOtherRequestList: auth => auth.isAgency || auth.isDeveloper || auth.isComplianceManager,
  canCreateCreativeModerationRequest(auth) {
    return (
      auth.isSaleManager ||
      auth.isMediaPlannerOrCSM ||
      auth.isComplianceManager ||
      auth.isAgencyClient ||
      auth.isAlexKosovskiy
    )
  },
}
