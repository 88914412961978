import {defineStore} from 'pinia'
import axios from '@/plugins/axios'
import {isStaging} from '@/plugins/helpers'

export const useAuthenticationStore = defineStore('authenticationStore', {
  state: () => ({
    isAuthenticated: false,
    user: {},
  }),
  getters: {
    authUser: state => {
      return state.user
    },
    userCompanyHash: state => {
      return state.user?.company?.hash
    },
    userHasCompany: state => {
      return !!state.user?.company
    },
    isDemoCompany: state => {
      return state.user?.company?.hash === 'lbMDVBRx' || false
    },
    userUnitId: state => {
      return state.user?.unit?.id
    },
    isIrynaOliinyk: state => {
      return state.user?.email === 'iryna.o@point2web.com' || null
    },
    isOlhaTurova: state => {
      return state.user?.email === 'olha.t@point2web.com' || null
    },
    isInternalUser: state => {
      return state.user.policies?.is_internal || false
    },
    isCoreTeam: state => {
      return state.user.policies?.is_core_team || false
    },
    businessUnit: state => {
      return state.user.policies?.business_unit || false
    },
    isComplianceManager: state => {
      return state.user.policies?.is_compliance || false
    },
    isMediaPlanner: state => {
      return state.user.policies?.is_media_planner || false
    },
    isCSM: state => {
      return state.user.policies?.is_csm || false
    },
    isMediaPlannerOrCSM: state => {
      return state.user.policies?.is_media_planner || state.user.policies?.is_csm
    },
    isDubei: state => {
      return state.user.email === 'serhii.d@point2web.com' || false
    },
    isTychko: state => {
      return state.user.email === 'oleksandr.t@point2web.com' || false
    },
    isAlexKosovskiy: state => {
      return state.user.email === 'alexander.k@point2web.com' || false
    },
    isMykytaKolpakov: state => {
      return state.user.email === 'mykyta.kolpakov@point2web.com' || false
    },
    isRizhnyak: state => {
      return state.user.email === 'bohdan.r@point2web.com' || false
    },
    isAgency: state => {
      return state.user.policies?.is_agency || false
    },
    isAgencyHeads: state => {
      return state.user.policies?.is_agency_heads || false
    },
    isMediaBuyer: state => {
      return state.user.policies?.is_media_buyer || false
    },
    isMediaBuyerTL: state => {
      return (state.user.policies?.is_media_buyer || false) && (state.user.is_manager || false)
    },
    isManager: state => {
      return !!state.user?.is_manager || false
    },
    isSaleManager: state => {
      return state.user.policies?.is_sale_manager || false
    },
    isOnboardingManager: state => {
      return state.user.policies?.is_onboarding_manager || false
    },
    isSaleManagerTeamLead: state => {
      return state.user.policies?.is_sale_manager_team_lead || false
    },
    isHomeImprovementUnit: state => {
      return state.user.policies?.is_home_improvement_unit || false
    },
    isHomeInsuranceUnit: state => {
      return state.user.policies?.is_home_insurance_unit || false
    },
    isBusinessUnitAgency: state => {
      return state.user.policies?.business_unit === 'agency' || false
    },
    isAgencyOrSupportBusinessUnit: state => {
      return ['agency', ''].includes(state.user.policies?.business_unit)
    },
    isBusinessUnitHomeIQ: state => {
      return state.user.policies?.business_unit === 'home_iq' || false
    },
    isSupportUnitEmployee: state => {
      return state.user.policies?.business_unit === '' || false
    },
    hasAccessToCompaniesPage: state => {
      return state.user.policies?.has_access_to_companies_page || false
    },
    canOpenAdAccountReport: state => {
      return state.user.policies?.can_open_ad_account_report || false
    },
    hasAccessToTopUps: state => {
      return (
        state.user.policies?.is_finance ||
        state.user.policies?.is_agency ||
        state.user.company?.top_ups_enabled ||
        false
      )
    },
    isAgencyAssistance: state => {
      return state.user.policies?.is_agency_assistance || false
    },
    isAgencyAssistanceTeamLead: state => {
      return state.user.policies?.is_agency_assistance_team_lead || false
    },
    isAgencyClient: state => {
      return state.user.policies?.is_agency_client || false
    },
    isFinance: state => {
      return state.user.policies?.is_finance || false
    },
    isDeveloper: state => {
      return state.user.policies?.is_developer || false
    },
    isDeveloperOrInternalCompany: state => {
      return state.user.policies?.is_developer_or_internal_company || false
    },
    isTechDepartment: state => {
      return state.user?.employee?.department === 'Tech' || false
    },
    isAnalyst: state => {
      return state.user.policies?.is_analyst || false
    },
    canUseTopUpAsap: state => {
      return state.user.policies?.asap_top_up_enabled || false
    },
    canUseDomains: state => {
      return state.user.policies?.can_use_domains || false
    },
    canUseInvoices: state => {
      return state.user.policies?.can_use_invoices || false
    },
    canWorkWithCreatives: state => {
      return state.user.policies?.can_work_with_creatives || false
    },
    canWorkWithOnboardings: state => {
      return state.user.policies?.can_work_with_onboardings || false
    },
    isLegal: state => {
      return state.user.policies?.is_legal || false
    },
    isLegalOrFinance: state => {
      return state.user.policies?.is_finance || state.user.policies?.is_legal || false
    },
    isPoint2WebEmployee: state => {
      return state.user.policies?.is_point2web_employee || false
    },
    isPoint2WebMarketing: state => {
      return state.user.policies?.is_point2web_marketing || false
    },

    fromSFCompany: state => {
      return state.user.policies?.from_sf_company || false
    },
    fromLGCompany: state => {
      return state.user.policies?.from_lg_company || false
    },
    hasTwoFA: state => {
      return state.user.two_fa_enabled || false
    },
    isContentProjects: state => {
      return state.user.policies?.is_content_projects || false
    },
    hasAccessToUsersPage: state => {
      return state.user.policies?.has_access_to_users_page || false
    },
    hasAccessToOverdrafts: state => {
      return state.user.policies?.has_access_to_overdrafts || false
    },
    hasAccessToRequestsPage: state => {
      return state.user.policies?.has_access_to_requests_page || false
    },
    hasAccessToProformaInvoices: state => {
      return state.user.policies?.has_access_to_proforma_invoices || false
    },
    hasAccessToAssetsPage: state => {
      return state.user.policies?.has_access_to_assets_page || false
    },
    isCompanyAdmin: state => {
      return (state.user?.company?.hash && state.user?.subrole === 'admin') || false
    },

    canSeeFacebookPages: getters => {
      return getters.fromLGCompany || getters.isAgencyAssistance || getters.isDeveloper
    },

    canCreateAgencyGeneralTask: getters => {
      return (
        getters.isMediaPlannerOrCSM || getters.isComplianceManager || getters.isSaleManager || getters.isAlexKosovskiy
      )
    },

    canCreateShortTask: getters => {
      return (
        getters.isAgency ||
        getters.fromLGCompany ||
        getters.isComplianceManager ||
        getters.fromSFCompany ||
        getters.isIrynaOliinyk ||
        (isStaging() && getters.isOlhaTurova)
      )
    },

    canCreateNewLandingTask: getters => {
      return (
        getters.isAgency ||
        getters.fromLGCompany ||
        getters.isComplianceManager ||
        getters.fromSFCompany ||
        getters.isContentProjects ||
        getters.isDeveloper ||
        getters.isDubei ||
        getters.isIrynaOliinyk ||
        (isStaging() && getters.isOlhaTurova)
      )
    },

    canCreateCopyLandingTask: getters => {
      return (
        getters.isAgency ||
        getters.fromLGCompany ||
        getters.isComplianceManager ||
        getters.fromSFCompany ||
        getters.isContentProjects ||
        getters.isDeveloper ||
        getters.isDubei ||
        getters.isIrynaOliinyk ||
        (isStaging() && getters.isOlhaTurova)
      )
    },

    canCreateABtestTask: getters => {
      return (
        getters.isAgency ||
        getters.fromLGCompany ||
        getters.fromSFCompany ||
        getters.isContentProjects ||
        getters.isDeveloper ||
        getters.isDubei ||
        getters.isIrynaOliinyk
      )
    },

    canCreateCreativeTask: getters => {
      return (
        getters.isAgency ||
        getters.isComplianceManager ||
        getters.fromLGCompany ||
        getters.fromSFCompany ||
        getters.isDubei ||
        getters.isContentProjects ||
        getters.isIrynaOliinyk ||
        (isStaging() && getters.isOlhaTurova)
      )
    },
    canCreateSMMTask: getters => {
      return (
        getters.isPoint2WebMarketing ||
        (getters.isAgency && !getters.isAgencyAssistance) ||
        getters.isIrynaOliinyk ||
        (isStaging() && getters.isOlhaTurova)
      )
    },
    canCreateOtherTask: getters => {
      return getters.isPoint2WebEmployee
    },
    canAddUserToPointboard: getters => {
      return getters.isTychko || getters.isRizhnyak
    },
    isGoogleUser: getters => {
      return getters.authUser.email === 'google@point2web.com'
    },
    requestsRouteName: getters => {
      if (getters.isFinance) {
        return 'ad-account-requests.top-up.list'
      }

      if (!getters.isPoint2WebEmployee) {
        return 'my-requests.list'
      }

      return 'ad-account-requests.accounts.list'
    },
  },
  actions: {
    login: async function (payload) {
      const {data} = await axios.post('auth/login', payload)

      await this.setToken(data.token)
      await this.loadAuthUserInfo()

      return data
    },

    setUserIsAuthenticated: async function (payload) {
      this.isAuthenticated = payload
    },

    loadAuthUserInfo: async function () {
      const {data} = await axios.get('user-info', {
        params: {with: ['company.organization', 'unit', 'employee.manager']},
      })

      this.user = data
      this.isAuthenticated = true
    },

    setToken: async function (token) {
      localStorage.setItem('user-token', token)

      await this.setUserIsAuthenticated(!!token)
    },

    updateUserProperty: async function (property, value) {
      this.user[property] = value
    },

    logout: async function () {
      localStorage.removeItem('user-token')

      await this.setUserIsAuthenticated(false)
    },

    logoutWithRequest: async function () {
      try {
        await axios.post('auth/logout')
      } finally {
        await this.logout()
      }
    },

    sendForgotPasswordRequest: async function (payload) {
      const {data} = await axios.post('auth/forgot-password', payload)

      return data
    },

    sendResetPasswordRequest: async function (payload) {
      const {data} = await axios.post('auth/reset-password', payload)

      return data
    },

    localLogin: async function (id) {
      const {data} = await axios.post('auth/local/login/' + id)

      await this.setToken(data.token)
      await this.loadAuthUserInfo()

      return data
    },

    impersonate: async function (user_id) {
      let originalUserId = localStorage.getItem('original_user_id') || this.user.id

      const {data} = await axios.post('auth/login/impersonate', {user_id})

      localStorage.clear()

      localStorage.setItem('original_user_id', originalUserId)

      this.setToken(data.token)

      return data
    },

    endImpersonation: async function (user_id) {
      const {data} = await axios.post('auth/login/end-impersonation', {user_id})

      this.setToken(data.token)

      localStorage.removeItem('original_user_id')

      return data
    },
  },
})
