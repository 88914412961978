<script setup>
import {storeToRefs} from 'pinia'
import {useAuthenticationStore} from '@/stores'
import {authenticationStore, mainRouter} from '@/main'
import ImpersonationUserSelect from '@/components/local/ImpersonationUserSelect.vue'
import {isDev, isLocal} from '@/plugins/helpers'
import RequestsDropdown from '@/components/ui/dropdowns/RequestsDropdown.vue'
import {accessRules} from '@/plugins/accessControl'
import TheBreadCrumbs from '@/components/TheBreadCrumbs.vue'
import MetaTitle from '@/components/ui/MetaTitle.vue'
import {computed} from 'vue'
import {useRoute} from 'vue-router'

const route = useRoute()
const auth = authenticationStore

const {logoutWithRequest} = useAuthenticationStore()
const {isAuthenticated, isDeveloper, isAgency} = storeToRefs(useAuthenticationStore())

// Computed
const showBreadCrumb = computed(
  () =>
    route.meta?.type === 'form' ||
    [
      'ad-alerts.list',
      'landings.list',
      'employees.list',
      'quick-books-customers.list',
      'request.agency-general',
      'conversions.list',
    ].includes(route.name),
)
const showLayoutTitle = computed(() => !showBreadCrumb.value)

// Methods
const handleLogout = async () => {
  await logoutWithRequest()

  await mainRouter.push({name: 'auth.login'})
}
</script>

<template>
  <div class="d-flex justify-content-between align-items-center">
    <div class="d-flex align-items-center">
      <the-bread-crumbs v-if="showBreadCrumb" class="ml-3" />

      <template v-if="showLayoutTitle">
        <h5 class="custom fs-7 fw-bold m-0">
          <meta-title />
        </h5>
      </template>
    </div>

    <div class="d-flex align-items-center custom gap-3" v-if="isAuthenticated">
      <requests-dropdown />

      <impersonation-user-select v-if="isLocal() || isDev() || isDeveloper || isAgency" />

      <div class="dropdown">
        <button
          id="dropdownUserButton"
          aria-expanded="false"
          class="btn btn-secondary user-btn"
          data-bs-toggle="dropdown"
          type="button"
        >
          <i class="fa-light fa-user"></i>
        </button>
        <ul aria-labelledby="dropdownUserButton" class="dropdown-menu">
          <li>
            <router-link :to="{name: 'user'}" class="dropdown-item">My Profile</router-link>
          </li>
          <li>
            <hr class="dropdown-divider" />
          </li>
          <template v-if="accessRules.hasAccessToUserSettings(auth)">
            <li>
              <router-link :to="{name: 'user.settings'}" class="dropdown-item">Users</router-link>
            </li>
            <li>
              <hr class="dropdown-divider" />
            </li>
          </template>
          <li>
            <button @click="handleLogout" class="dropdown-item">Log out</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user-btn {
  color: #6b7280 !important;
  padding: 5px 9px !important;
}
</style>
