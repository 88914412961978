<script setup>
import {onMounted, defineEmits, ref, defineProps, defineExpose} from 'vue'

// Props & emits
let emit = defineEmits(['file-updated', 'delete-file', 'changed'])

const props = defineProps({
  activeRequestFields: {
    type: Object,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const defaultFormData = {
  service_url: '',
  test_login: '',
  test_password: '',
  geo: '',
}
let formData = ref(structuredClone(defaultFormData))

// Methods
onMounted(() => {
  init()
})

const init = () => {
  if (props.activeRequestFields) {
    formData.value = Object.assign(formData.value, props.activeRequestFields)
  }
}

const resetForm = () => {
  formData.value = structuredClone(defaultFormData)
}

const onChange = () => {
  emit('changed', {fields: formData.value})
}

defineExpose({resetForm})
</script>

<template>
  <div class="form-group">
    <label for="google-dating-wl-service_url">Dating Service URL *</label>
    <div class="input-group">
      <input
        v-model="formData.service_url"
        @change="onChange"
        class="form-control"
        id="google-dating-wl-service_url"
        type="url"
        :disabled="disabled"
        required
      />
    </div>
  </div>
  <div class="form-group">
    <label for="google-dating-wl-login">Test Login (if needed)</label>
    <div class="input-group">
      <input
        v-model="formData.test_login"
        @change="onChange"
        :disabled="disabled"
        class="form-control"
        id="google-dating-wl-login"
      />
    </div>
  </div>
  <div class="form-group">
    <label for="google-dating-wl-password">Test Password (if needed)</label>
    <div class="input-group">
      <input
        v-model="formData.test_password"
        @change="onChange"
        :disabled="disabled"
        class="form-control"
        id="google-dating-wl-password"
      />
    </div>
  </div>
  <div class="form-group">
    <label for="google-dating-wl-geo">Geo *</label>
    <div class="input-group">
      <input
        v-model="formData.geo"
        @change="onChange"
        class="form-control"
        id="google-dating-wl-geo"
        type="text"
        :disabled="disabled"
        required
      />
    </div>
  </div>
</template>
