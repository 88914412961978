<script setup>
import {ref, onMounted, defineProps} from 'vue'
import {notify} from '@kyvg/vue3-notification'
import {useUserStore} from '@/stores/UserStore'
import {useAuthenticationStore} from '@/stores'
import {storeToRefs} from 'pinia'
import {findFirstAvailableRoute, isRouteActive} from '@/plugins/helpers'
import {accessRules} from '@/plugins/accessControl'
import {authenticationStore} from '@/main'
import SideBarDropdown from '@/components/side-bar/SideBarDropdown.vue'

const auth = authenticationStore

const {isPoint2WebEmployee, isAgencyClient, hasAccessToProformaInvoices, requestsRouteName} = storeToRefs(
  useAuthenticationStore(),
)
const {getUserManager} = useUserStore()

const props = defineProps({
  fullHeight: {
    type: Boolean,
    default: true,
  },
})

// Refs
const managerLoading = ref(false)
const hasNoManager = ref(false)
const managersInfo = ref([
  {
    full_name: '',
    position: '',
    photo_path: null,
    manager_type: '',
    email: '',
    skype: '',
    telegram: '',
  },
])

// Methods
onMounted(() => {
  if (!isPoint2WebEmployee.value) {
    getManagerInfo()
  }
})

const getManagerInfo = async () => {
  managerLoading.value = true

  try {
    const managers = await getUserManager()

    if (!managers || managers.length === 0) {
      hasNoManager.value = true
    } else {
      managersInfo.value = managers
    }
  } catch (error) {
    hasNoManager.value = true

    notify({type: 'error', text: error.data.message})
  } finally {
    managerLoading.value = false
  }
}

const showManagerType = manager_type => {
  return manager_type === 'media_planner_social' ? 'Social' : 'Search'
}
</script>

<template>
  <div :class="['bg-white pb-4 h-100 sidebar', props.fullHeight ? 'min-vh-100' : 'sidebar-height']">
    <div class="position-sticky top-0">
      <div class="p-4">
        <img src="../../assets/images/sidebar/pb_logo.webp" alt="pointboard" class="max-w-150-px d-block" />
      </div>
      <hr class="m-0" />

      <ul class="nav nav-pills flex-column pt-3" id="menu">
        <li>
          <div class="nav-item">
            <router-link
              :class="['nav-link', isRouteActive('home').value ? 'active' : '']"
              :to="{name: 'home'}"
              active-class="active"
            >
              <span class="nav-icon">
                <i class="fa-light fa-house"></i>
              </span>
              <span class="nav-text">Home Page</span>
            </router-link>
          </div>
        </li>
        <li v-if="accessRules.hasAccessToDashboard(auth)">
          <div class="nav-item">
            <router-link
              :to="{name: 'dashboard'}"
              :class="['nav-link', isRouteActive('dashboard').value ? 'active' : '']"
              active-class="active"
            >
              <span class="nav-icon">
                <i class="fa-light fa-table-columns"></i>
              </span>

              <span class="nav-text">Dashboard</span>
            </router-link>
          </div>
        </li>
        <li v-if="accessRules.hasAccessToRequestsPage(auth)">
          <div class="nav-item">
            <router-link
              :class="['nav-link', isRouteActive('ad-account-requests').value ? 'active' : '']"
              :to="{name: requestsRouteName}"
              active-class="active"
            >
              <span class="nav-icon">
                <i class="fa-light fa-files"></i>
              </span>

              <span class="nav-text">Requests</span>
            </router-link>
          </div>
        </li>
        <li v-if="accessRules.hasAccessToCreativeModerationList(auth)">
          <div class="nav-item">
            <router-link
              :to="{name: 'creatives.list'}"
              :class="['nav-link justify-content-between', isRouteActive('creatives.list').value ? 'active' : '']"
              active-class="active"
            >
              <div class="d-flex align-items-center">
                <span class="nav-icon">
                  <i class="fa-light fa-image"></i>
                </span>
                <span class="nav-text">Creatives Moderation</span>
              </div>
              <div v-if="false" class="small-badge-light-border ml-2">Beta</div>
            </router-link>
          </div>
        </li>
        <li v-if="accessRules.hasAccessToCompaniesPage(auth)">
          <div class="nav-item">
            <router-link
              :to="{name: 'companies.list'}"
              :class="['nav-link', isRouteActive('companies.list').value ? 'active' : '']"
              active-class="active"
            >
              <span class="nav-icon">
                <i class="fa-light fa-sitemap"></i>
              </span>

              <span class="nav-text">Companies</span>
            </router-link>
          </div>
        </li>
        <li v-if="accessRules.hasAccessToAssetsPage(auth)">
          <side-bar-dropdown>
            <template #button>
              <span class="nav-icon">
                <i class="fa-light fa-images-user"></i>
              </span>

              <span class="nav-text">Ad Accounts & Pages</span>
            </template>
            <template #menu>
              <li>
                <router-link
                  :to="{name: 'assets.ad-accounts.list'}"
                  :class="['nav-dropdown-menu-item', isRouteActive('assets.ad-accounts.list').value ? 'active' : '']"
                  active-class="active"
                >
                  <span class="nav-text">Ad Accounts</span>
                </router-link>
              </li>
              <li v-if="accessRules.hasAccessToAccountConnections(auth)">
                <router-link
                  :to="{name: 'assets.ad-account-connections.list'}"
                  :class="[
                    'nav-dropdown-menu-item',
                    isRouteActive('assets.ad-account-connections.list').value ? 'active' : '',
                  ]"
                  active-class="active"
                >
                  <span class="nav-text">Ad Accounts Connections</span>
                </router-link>
              </li>
              <li v-if="accessRules.hasAccessToBusinessManagers(auth)">
                <router-link
                  :to="{name: 'assets.business-managers.list'}"
                  :class="[
                    'nav-dropdown-menu-item',
                    isRouteActive('assets.business-managers.list').value ? 'active' : '',
                  ]"
                  active-class="active"
                >
                  <span class="nav-text">Business Managers</span>
                </router-link>
              </li>
              <li v-if="accessRules.hasAccessToFacebookLicense(auth)">
                <router-link
                  :to="{name: 'assets.facebook-license.list'}"
                  :class="[
                    'nav-dropdown-menu-item',
                    isRouteActive('assets.facebook-license.list').value ? 'active' : '',
                  ]"
                  active-class="active"
                >
                  <span class="nav-text">Chinese License</span>
                </router-link>
              </li>
              <li v-if="accessRules.hasAccessToFacebookPages(auth)">
                <router-link
                  :to="{name: 'assets.facebook-pages.list'}"
                  :class="['nav-dropdown-menu-item', isRouteActive('assets.facebook-pages.list').value ? 'active' : '']"
                  active-class="active"
                >
                  <span class="nav-text">FB Pages</span>
                </router-link>
              </li>
            </template>
          </side-bar-dropdown>
        </li>

        <li v-if="accessRules.hasAccessToFinancePage(auth)">
          <div class="nav-item">
            <router-link
              :to="{
                name: isAgencyClient
                  ? 'payments.daily_balance'
                  : hasAccessToProformaInvoices
                  ? 'proforma-invoices.list'
                  : 'payments.list',
              }"
              :class="['nav-link', isRouteActive('finance').value ? 'active' : '']"
              active-class="active"
            >
              <span class="nav-icon">
                <i class="fa-light fa-coins"></i>
              </span>

              <span class="nav-text">{{ isAgencyClient ? 'Financial Overview' : 'Finance' }}</span>
            </router-link>
          </div>
        </li>
        <li v-if="accessRules.hasAccessToTracker(auth)">
          <div class="nav-item">
            <router-link
              :to="{name: 'campaigns.list'}"
              :class="['nav-link', isRouteActive('tracker').value ? 'active' : '']"
              active-class="active"
            >
              <span class="nav-icon">
                <i class="fa-light fa-chart-mixed-up-circle-currency"></i>
              </span>

              <span class="nav-text">Tracker</span>
            </router-link>
          </div>
        </li>
        <li v-if="accessRules.hasAccessToReports(auth)">
          <div class="nav-item">
            <router-link
              :to="{name: 'reports'}"
              active-class="active"
              :class="['nav-link', isRouteActive('reports').value ? 'active' : '']"
            >
              <span class="nav-icon">
                <i class="fa-light fa-chart-pie"></i>
              </span>

              <span class="nav-text">Reports</span>
            </router-link>
          </div>
        </li>
        <li v-if="accessRules.hasAccessToTools(auth)">
          <div class="nav-item">
            <router-link
              :to="{
                name: findFirstAvailableRoute('tools', auth).name,
              }"
              :class="['nav-link', isRouteActive('tools').value ? 'active' : '']"
              active-class="active"
            >
              <span class="nav-icon">
                <i class="fa-light fa-screwdriver-wrench"></i>
              </span>

              <span class="nav-text">Tools</span>
            </router-link>
          </div>
        </li>
        <li v-if="accessRules.hasAccessToClientOnboarding(auth)">
          <div class="nav-item">
            <router-link
              :to="{name: 'onboarding'}"
              :class="['nav-link', isRouteActive('onboarding').value ? 'active' : '']"
              active-class="active"
            >
              <span class="nav-icon">
                <i class="fa-light fa-user-plus"></i>
              </span>

              <span class="nav-text">Onboarding</span>
            </router-link>
          </div>
        </li>
        <li v-if="accessRules.hasAccessToClickupPage(auth)">
          <div class="nav-item">
            <router-link
              :to="{name: 'clickup.list'}"
              :class="['nav-link', isRouteActive('clickup.list').value ? 'active' : '']"
              active-class="active"
            >
              <span class="nav-icon">
                <i class="fa-light fa-list-ul"></i>
              </span>

              <span class="nav-text">ClickUp</span>
            </router-link>
          </div>
        </li>
        <template v-if="!isPoint2WebEmployee">
          <li>
            <div class="nav-item">
              <a
                href="https://point2web.com/creative-studio/"
                rel="nofollow noopener noreferrer"
                target="_blank"
                class="nav-link justify-content-between"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-icon">
                    <i class="fa-light fa-lightbulb"></i>
                  </span>
                  <span class="nav-text">Creative Studio</span>
                </div>
                <i class="fas fa-external-link-alt pl-15-px"></i>
              </a>
            </div>
          </li>
          <li>
            <div class="nav-item">
              <a
                href="https://point2web.com/performance-network/"
                rel="nofollow noopener noreferrer"
                target="_blank"
                class="nav-link justify-content-between"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-icon">
                    <i class="fa-light fa-gauge-max"></i>
                  </span>

                  <span class="nav-text">Performance Network</span>
                </div>
                <i class="fas fa-external-link-alt pl-15-px"></i>
              </a>
            </div>
          </li>
        </template>
      </ul>

      <div class="pt-3" v-if="!isPoint2WebEmployee && !hasNoManager">
        <hr class="m-0 pb-3" />
        <div v-for="(manager, index) in managersInfo" :key="index">
          <div class="px-4">
            <p class="fw-bold" v-if="index === 0">I'm here to help you:</p>
            <div class="d-flex gap-3 align-items-center">
              <div v-if="manager.photo_path" class="b-radius-50 w-80-px overflow-hidden border-2-grey">
                <img :src="manager.photo_path" :alt="manager.full_name" class="d-block max-w-100 w-100" />
              </div>
              <div>
                <p class="fw-bold mb-0">{{ manager.full_name }} ({{ showManagerType(manager.manager_type) }})</p>
                <p class="mb-3">{{ manager.position }}</p>
                <div class="d-flex gap-2 align-items-center">
                  <a
                    v-if="manager.skype"
                    :href="`skype:${manager.skype}?chat`"
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                    class="d-block b-radius-50 h-30-px w-30-px"
                  >
                    <img
                      src="../../assets/images/sidebar/socials/skype.webp"
                      alt="skype"
                      class="d-block max-w-100 w-100"
                    />
                  </a>
                  <a
                    v-if="manager.telegram"
                    :href="manager.telegram"
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                    class="d-block b-radius-50 h-30-px w-30-px"
                  >
                    <img
                      src="../../assets/images/sidebar/socials/telegram.webp"
                      alt="telegram"
                      class="d-block max-w-100 w-100"
                    />
                  </a>
                  <a
                    v-if="manager.email"
                    :href="`mailto:${manager.email}`"
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                    class="d-block b-radius-50 h-30-px w-30-px"
                  >
                    <img
                      src="../../assets/images/sidebar/socials/email.webp"
                      alt="email"
                      class="d-block max-w-100 w-100"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <hr v-if="index + 1 < managersInfo.length" />
        </div>
      </div>
    </div>
  </div>
</template>
