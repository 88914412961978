<script setup>
import {onMounted, defineEmits, ref, defineProps, defineExpose} from 'vue'

// Props & emits
let emit = defineEmits(['file-updated', 'delete-file', 'changed'])

const props = defineProps({
  activeRequestFields: {
    type: Object,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const defaultFormData = {
  email: '',
  legal_company_name: '',
  offer_url: '',
  address: '',
  preland_url: '',
  city: '',
  zip: '',
  auth_url: '',
  comment: '',
}
let formData = ref(structuredClone(defaultFormData))

// Methods
onMounted(() => {
  init()
})

const init = () => {
  if (props.activeRequestFields) {
    formData.value = Object.assign(formData.value, props.activeRequestFields)
  }
}

const resetForm = () => {
  formData.value = structuredClone(defaultFormData)
}

const onChange = () => {
  emit('changed', {fields: formData.value})
}

defineExpose({resetForm})
</script>

<template>
  <div class="form-group">
    <label for="snapchat-financial-wl-email">Client email *</label>
    <div class="input-group">
      <input
        v-model="formData.email"
        @change="onChange"
        class="form-control"
        id="snapchat-financial-wl-email"
        type="email"
        :disabled="disabled"
        required
      />
    </div>
  </div>
  <div class="form-group">
    <label for="snapchat-financial-wl-legal_company_name">Company name *</label>
    <div class="input-group">
      <input
        v-model="formData.legal_company_name"
        @change="onChange"
        class="form-control"
        id="snapchat-financial-wl-legal_company_name"
        :disabled="disabled"
        required
      />
    </div>
  </div>

  <div class="form-group">
    <label for="snapchat-financial-wl-offer_url">Offer URL *</label>
    <div class="input-group">
      <input
        v-model="formData.offer_url"
        @change="onChange"
        class="form-control"
        id="snapchat-financial-wl-offer_url"
        type="url"
        :disabled="disabled"
        required
      />
    </div>
  </div>
  <div class="form-group">
    <label for="snapchat-financial-wl-preland_url">Preland (if there is one)</label>
    <div class="input-group">
      <input
        v-model="formData.preland_url"
        @change="onChange"
        class="form-control"
        id="snapchat-financial-wl-preland_url"
        :disabled="disabled"
        type="url"
      />
    </div>
  </div>
  <div class="form-group">
    <label for="snapchat-financial-wl-address">Address (street, house number) *</label>
    <div class="input-group">
      <input
        v-model="formData.address"
        @change="onChange"
        class="form-control"
        id="snapchat-financial-wl-address"
        :disabled="disabled"
        required
      />
    </div>
  </div>
  <div class="form-group">
    <label for="snapchat-financial-wl-city">City *</label>
    <div class="input-group">
      <input
        v-model="formData.city"
        @change="onChange"
        class="form-control"
        id="snapchat-financial-wl-city"
        :disabled="disabled"
        required
      />
    </div>
  </div>
  <div class="form-group">
    <label for="snapchat-financial-wl-zip">Zip Code *</label>
    <div class="input-group">
      <input
        v-model="formData.zip"
        @change="onChange"
        class="form-control"
        id="snapchat-financial-wl-zip"
        :disabled="disabled"
        required
      />
    </div>
  </div>
  <div class="form-group">
    <label for="snapchat-financial-wl-country">Country (principal place of business or headquarters) *</label>
    <div class="input-group">
      <input
        v-model="formData.country"
        @change="onChange"
        class="form-control"
        id="snapchat-financial-wl-country"
        :disabled="disabled"
        required
      />
    </div>
  </div>
  <div class="form-group">
    <label for="snapchat-financial-wl-auth_url">URL to authorization (or to a Google file with a documents) *</label>
    <div class="input-group">
      <input
        v-model="formData.auth_url"
        @change="onChange"
        class="form-control"
        id="snapchat-financial-wl-auth_url"
        :disabled="disabled"
        type="url"
        required
      />
    </div>
  </div>
  <div class="form-group">
    <label for="snapchat-financial-wl-comment">Any comments that you would like to add</label>
    <div class="input-group">
      <textarea
        v-model="formData.comment"
        @change="onChange"
        class="form-control"
        id="snapchat-financial-wl-comment"
        :disabled="disabled"
        rows="3"
      />
    </div>
  </div>
</template>
