import AdminLayout from '@/layouts/AdminLayout.vue'
import {authenticationStore} from '@/main'
import PageNotFound from '@/views/PageNotFound.vue'
import {useCompanyStore} from '@/views/companies/CompanyStore'
import {useUserStore} from '@/stores/UserStore'
import {setPageTitle} from '@/plugins/helpers'
import {useLandingsStore} from '@/views/tracker/landings/LandingsStore'

export default {
  path: '/',
  name: 'content',
  component: AdminLayout,
  beforeEnter: (to, from, next) => {
    if (!authenticationStore.isAuthenticated) {
      return next({
        name: 'auth.login',
        query: {
          redirect_url: window.location.href,
        },
      })
    }

    if (!authenticationStore.hasTwoFA && authenticationStore.isPoint2WebEmployee && to.name !== 'two-fa') {
      return next({
        name: 'two-fa',
        query: {
          redirect_url: window.location.href,
        },
      })
    }

    if (authenticationStore.isCoreTeam) {
      let {getCompanies} = useCompanyStore()
      let {loadEmployees} = useUserStore()
      let {getUnits} = useLandingsStore()

      getCompanies()
      loadEmployees()
      getUnits()
    } else if (authenticationStore.isDeveloper) {
      let {getUnits} = useLandingsStore()

      getUnits()
    }

    next()
  },

  redirect: {
    name: 'home',
  },

  children: [
    // Dashboard
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/DashboardView.vue'),
      meta: {
        title: 'Dashboard',
        access: ['hasAccessToDashboard'],
      },
    },

    // Dashboard
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/home-page/HomePageView.vue'),
      meta: {
        title: 'Home Page',
      },
    },

    // User Profile
    {
      path: 'user',
      name: 'user',
      redirect: {
        name: 'user.profile',
      },
      children: [
        {
          path: 'profile',
          name: 'user.profile',
          component: () => import('@/views/user-main/UserMain.vue'),
          meta: {
            title: 'My Profile',
            parent: 'user',
          },
        },
        {
          path: 'notifications',
          name: 'user.notifications',
          component: () => import('@/views/user-main/UserMain.vue'),
          meta: {
            title: 'Notifications',
            parent: 'user',
          },
        },
        {
          path: 'security',
          name: 'user.security',
          component: () => import('@/views/user-main/UserMain.vue'),
          meta: {
            title: 'Security',
            parent: 'user',
          },
        },
      ],
    },
    {
      path: 'user-settings',
      name: 'user.settings',
      redirect: {
        name: 'user.settings.users.list',
      },
      meta: {
        access: ['hasAccessToUserSettings'],
      },
      children: [
        {
          path: 'users-list',
          name: 'user.settings.users.list',
          component: () => import('@/views/user-main/settings/UserSettings.vue'),
          meta: {
            title: 'Users',
            parent: 'user.settings',
          },
        },
      ],
    },

    {
      path: 'two-fa',
      name: 'two-fa',
      component: () => import('@/views/auth/TwoFaForm.vue'),
      meta: {
        title: 'Setup two-factor authentication',
      },
    },

    // Ad account requests
    {
      name: 'ad-account-requests',
      path: 'ad-account-requests',
      meta: {
        title: 'Requests',
        access: ['hasAccessToRequestsPage'],
      },
      alias: 'meta-ad-account-requests',
      redirect: {
        name: 'ad-account-requests.accounts.list',
      },
      children: [
        {
          path: 'my-requests',
          name: 'my-requests.list',
          component: () => import('@/views/ad-account-requests/AllAdAccountRequests.vue'),
          meta: {
            parent: 'ad-account-requests',
            title: 'All My Requests',
            access: ['hasAccessToMyRequests'],
          },
        },
        {
          path: 'accounts',
          name: 'ad-account-requests.accounts.list',
          component: () => import('@/views/ad-account-requests/AllAdAccountRequests.vue'),
          meta: {
            parent: 'ad-account-requests',
            title: 'Ad Account Requests',
          },
        },
        {
          path: 'rename',
          name: 'ad-account-requests.rename.list',
          component: () => import('@/views/ad-account-requests/AllAdAccountRequests.vue'),
          meta: {
            parent: 'ad-account-requests',
            title: 'Rename Account Requests',
          },
        },
        {
          path: 'top-up',
          name: 'ad-account-requests.top-up.list',
          component: () => import('@/views/ad-account-requests/AllAdAccountRequests.vue'),
          meta: {
            parent: 'ad-account-requests',
            title: 'Top-Up Requests',
          },
        },
        {
          path: 'facebook-pages-requests',
          name: 'ad-account-requests.facebook-pages-requests.list',
          component: () => import('@/views/ad-account-requests/AllAdAccountRequests.vue'),
          meta: {
            parent: 'ad-account-requests',
            title: 'FB Page Requests',
          },
        },
        {
          path: 'whitelist',
          name: 'whitelist',
          component: () => import('@/views/ad-account-requests/AllAdAccountRequests.vue'),
          meta: {
            parent: 'ad-account-requests',
            title: 'Whitelist Requests',
          },
        },
        {
          path: 'share-permissions/:hash?',
          name: 'share-permissions',
          component: () => import('@/views/ad-account-requests/AllAdAccountRequests.vue'),
          meta: {
            parent: 'ad-account-requests',
            title: 'Share Access Requests',
          },
        },
        {
          path: 'creative-requests/list',
          name: 'creative-requests.list',
          component: () => import('@/views/ad-account-requests/AllAdAccountRequests.vue'),
          meta: {
            title: 'Creative Moderation Requests',
            parent: 'ad-account-requests',
            access: ['hasAccessToCreativeModerationRequest'],
          },
        },
        {
          path: 'client-pre-moderation/list',
          name: 'client-pre-moderation.list',
          component: () => import('@/views/ad-account-requests/AllAdAccountRequests.vue'),
          meta: {
            title: 'Client Pre-Moderation Requests',
            parent: 'ad-account-requests',
            access: ['hasAccessToClientPreModerationList'],
          },
        },
        {
          path: 'agency-general',
          name: 'request.agency-general',
          component: () => import('@/views/tasks/AgencyGeneralTask.vue'),
          meta: {
            parent: 'ad-account-requests',
            title: 'Client Pre-Moderation Request',
            access: ['hasAccessToAgencyGeneralTask'],
          },
        },

        {
          path: 'other-requests/list',
          name: 'other-requests.list',
          component: () => import('@/views/ad-account-requests/AllAdAccountRequests.vue'),
          meta: {
            title: 'Other Requests',
            parent: 'ad-account-requests',
            access: ['hasAccessToOtherRequestList'],
          },
        },

        // Meta  accounts request
        {
          path: 'meta/:id?',
          name: 'meta-ad-account-request',
          alias: 'meta-ad-account-requests/:id?',
          component: () => import('@/views/ad-account-requests/accounts/meta/MetaAdAccountRequest.vue'),
          meta: {
            title: 'Meta Ad Account Request',
            parent: 'ad-account-requests',
            platform: 'Meta',
            type: 'form',
          },
        },

        // Google accounts request
        {
          path: 'google/:id?',
          name: 'google-ad-account-request',
          component: () => import('@/views/ad-account-requests/accounts/google/GoogleAdAccountRequest.vue'),
          meta: {
            title: 'Google Ad Account Request',
            parent: 'ad-account-requests',
            platform: 'Google',
            type: 'form',
          },
        },

        // Tiktok accounts request
        {
          path: 'tiktok/:id?',
          name: 'tiktok-ad-account-request',
          component: () => import('@/views/ad-account-requests/accounts/tiktok/TiktokAdAccountRequest.vue'),
          meta: {
            title: 'TikTok Ad Account Request',
            parent: 'ad-account-requests',
            platform: 'TikTok',
            type: 'form',
          },
        },

        // Bigo accounts request
        {
          path: 'bigo/:id?',
          name: 'bigo-ad-account-request',
          component: () => import('@/views/ad-account-requests/accounts/bigo/BigoAdAccountRequest.vue'),
          meta: {
            title: 'Bigo Ads Ad Account Request',
            parent: 'ad-account-requests',
            platform: 'Bigo',
            type: 'form',
          },
        },

        // Bing accounts request
        {
          path: 'bing/:id?',
          name: 'bing-ad-account-request',
          alias: 'bing-ad-account-request',
          component: () => import('@/views/ad-account-requests/accounts/bing/BingAdAccountRequest.vue'),
          meta: {
            title: 'Microsoft Ads Ad Account Request',
            parent: 'ad-account-requests',
            platform: 'Microsoft Ads',
            type: 'form',
          },
        },

        // Snapchat accounts request
        {
          path: 'snapchat/:id?',
          name: 'snapchat-ad-account-request',
          component: () => import('@/views/ad-account-requests/accounts/snapchat/SnapchatAdAccountRequest.vue'),
          meta: {
            title: 'Snapchat Ad Account Request',
            parent: 'ad-account-requests',
            platform: 'Snapchat',
            type: 'form',
          },
        },

        // Revcontent accounts request
        {
          path: 'revcontent/:id?',
          name: 'revcontent-ad-account-request',
          component: () => import('@/views/ad-account-requests/accounts/revcontent/RevcontentAdAccountRequest.vue'),
          meta: {
            title: 'Revcontent Ad Account Request',
            parent: 'ad-account-requests',
            platform: 'Revcontent',
            type: 'form',
          },
        },
        // Newsbreak accounts request
        {
          path: 'newsbreak/:id?',
          name: 'newsbreak-ad-account-request',
          component: () => import('@/views/ad-account-requests/accounts/newsbreak/NewsbreakAdAccountRequest.vue'),
          meta: {
            title: 'NewsBreak Ad Account Request',
            parent: 'ad-account-requests',
            platform: 'NewsBreak',
            type: 'form',
          },
        },
        // Taboola accounts request
        {
          path: 'taboola/:id?',
          name: 'taboola-ad-account-request',
          component: () => import('@/views/ad-account-requests/accounts/taboola/TaboolaAdAccountRequest.vue'),
          meta: {
            title: 'Taboola Ad Account Request',
            parent: 'ad-account-requests',
            platform: 'Taboola',
            type: 'form',
          },
        },
        // Outbrain accounts request
        {
          path: 'outbrain/:id?',
          name: 'outbrain-ad-account-request',
          component: () => import('@/views/ad-account-requests/accounts/outbrain/OutbrainAdAccountRequest.vue'),
          meta: {
            title: 'Outbrain Ad Account Request',
            parent: 'ad-account-requests',
            platform: 'Outbrain',
            type: 'form',
          },
        },
      ],
    },
    {
      path: 'creatives',
      name: 'creatives',
      redirect: {
        name: 'creatives.list',
      },

      children: [
        {
          path: 'list',
          name: 'creatives.list',
          component: () => import('@/views/creatives/AdCreativeRequestsList.vue'),
          meta: {
            title: 'Creative Moderation Requests',
            access: ['hasAccessToCreativeModerationList'],
          },
        },

        {
          path: 'profile/:id?',
          name: 'creatives.profile',
          component: () => import('@/views/creatives/AdCreativeRequest.vue'),
          meta: {
            title: 'New Request',
            type: 'form',
            access: ['hasAccessToCreativeModerationProfile'],
          },
          beforeEnter: (to, from) => {
            if (from.name === 'creative-requests.list') {
              to.meta.parent = 'creative-requests.list'
            }

            if (to.params.id) {
              to.meta.title = `Request #${to.params.id}`
            } else {
              to.meta.title = 'Creative Moderation Request'
            }

            if (authenticationStore.isAgency || authenticationStore.isComplianceManager) {
              to.meta.parent = 'creative-requests.list'
            } else {
              to.meta.parent = 'creatives.list'
            }
          },
        },
      ],
    },

    // Clients onboarding
    {
      path: 'onboarding',
      name: 'onboarding',
      redirect: {
        name: 'onboarding.list',
      },
      children: [
        {
          path: 'list',
          name: 'onboarding.list',
          component: () => import('@/views/onboarding/OnboardingList.vue'),
          meta: {
            title: 'Clients Onboarding',
            access: ['hasAccessToClientOnboarding'],
          },
        },
        {
          path: 'document/:hash',
          name: 'onboarding.document',
          component: () => import('@/views/onboarding/OnboardingDocument.vue'),
          meta: {
            access: ['hasAccessToOnboardingDocument'],
          },
        },
      ],
    },

    // Companies
    {
      path: 'companies',
      redirect: {
        name: 'companies.list',
      },
      children: [
        {
          path: '',
          name: 'companies.list',
          component: () => import('@/views/companies/CompaniesList.vue'),
          meta: {
            title: 'Companies',
            access: ['hasAccessToCompaniesPage'],
          },
        },
        {
          path: 'users',
          name: 'users.list',
          component: () => import('@/views/companies/users/UsersList.vue'),
          meta: {
            title: 'Users',
            parent: 'companies.list',
          },
        },
        {
          path: 'quick-books-customers',
          name: 'quick-books-customers.list',
          component: () => import('@/views/companies/quick-books-customers/QuickBooksCustomersList.vue'),
          meta: {
            title: 'Quick Books Customers',
            parent: 'companies.list',
          },
        },
        {
          path: 'employees',
          name: 'employees.list',
          component: () => import('@/views/companies/employees/EmployeesList.vue'),
          meta: {
            title: 'Employees',
            parent: 'companies.list',
            access: ['hasAccessToEmployeeList'],
          },
        },
        {
          path: ':hash/documents',
          name: 'company.documents',
          component: () => import('@/views/companies/documents/CompanyDocuments.vue'),
          meta: {
            title: 'Company Documents',
            parent: 'companies.list',
          },
        },
      ],
    },
    // Assets
    {
      path: 'assets',
      name: 'assets.list',
      redirect: {
        name: 'assets.business-managers.list',
      },
      meta: {
        access: ['hasAccessToAssetsPage'],
      },
      children: [
        {
          path: 'business-managers',
          name: 'assets.business-managers.list',
          alias: 'meta-business-managers',

          component: () => import('@/views/assets/business-managers/BusinessManagersList.vue'),
          meta: {
            title: 'Business Managers',
            parent: 'assets.list',
            access: ['hasAccessToBusinessManagers'],
          },
        },
        {
          path: 'ad-accounts',
          name: 'assets.ad-accounts.list',
          alias: 'meta-ad-accounts',
          component: () => import('@/views/assets/ad-accounts/AdAccountsList.vue'),
          meta: {
            parent: 'assets.list',
            title: 'Ad Accounts',
          },
        },
        {
          path: 'ad-account-connections',
          name: 'assets.ad-account-connections.list',
          component: () => import('@/views/assets/ad-accounts/AdAccountConnectionsList.vue'),
          meta: {
            parent: 'assets.list',
            title: 'Ad Account Connections',
            access: ['hasAccessToAccountConnections'],
          },
        },
        {
          path: 'facebook-pages',
          name: 'assets.facebook-pages.list',
          component: () => import('@/views/assets/facebook-pages/FacebookPagesList.vue'),
          meta: {
            parent: 'assets.list',
            title: 'FB Pages',
            access: ['hasAccessToFacebookPages'],
          },
        },
        {
          path: 'facebook-license',
          name: 'assets.facebook-license.list',
          component: () => import('@/views/assets/facebook-licenses/FacebookLicenseList.vue'),
          meta: {
            parent: 'assets.list',
            title: 'Chinese License',
            access: ['hasAccessToFacebookLicense'],
          },
        },
      ],
    },

    // Invoices
    {
      path: 'finance',
      name: 'finance',
      redirect: {
        name: 'proforma-invoices.list',
      },
      meta: {
        access: ['hasAccessToFinancePage'],
      },
      children: [
        {
          path: 'payments/daily-balance',
          name: 'payments.daily_balance',
          component: () => import('@/views/finance/TheFinance.vue'),
          meta: {
            title: 'Daily Balance',
            parent: 'finance',
            access: ['hasAccessToPaymentsDailyBalance'],
          },
        },

        {
          path: 'proforma-invoices',
          name: 'proforma-invoices.list',
          component: () => import('@/views/finance/TheFinance.vue'),
          meta: {
            title: 'Proforma Invoices',
            parent: 'finance',
          },
        },

        {
          path: 'invoices',
          name: 'invoices.list',
          component: () => import('@/views/finance/TheFinance.vue'),
          meta: {
            title: 'Invoices',
            parent: 'finance',
            access: ['hasAccessToInvoices'],
          },
        },
        {
          path: 'payments',
          name: 'payments.list',
          component: () => import('@/views/finance/TheFinance.vue'),
          meta: {
            title: 'Payments',
            parent: 'finance',
          },
        },
      ],
    },
    // Campaigns
    {
      path: 'tracker',
      name: 'tracker',
      meta: {
        title: 'Tracker',
        access: ['hasAccessToTracker'],
      },
      redirect: {
        name: 'campaigns.list',
      },
      children: [
        {
          path: 'campaigns',
          name: 'campaigns.list',
          component: () => import('@/views/tracker/TrackerList.vue'),
          meta: {
            title: 'Campaigns',
            parent: 'tracker',
          },
        },
        {
          path: 'campaigns/create',
          name: 'campaigns.create',
          component: () => import('@/views/tracker/campaigns/profile/CampaignProfile.vue'),
          meta: {
            title: 'New campaign',
            parent: 'campaigns.list',
            type: 'form',
          },
        },
        {
          path: 'campaigns/:hash',
          name: 'campaigns.edit',
          component: () => import('@/views/tracker/campaigns/profile/CampaignProfile.vue'),
          meta: {
            title: 'Edit campaign',
            parent: 'campaigns.list',
            type: 'form',
          },
        },

        {
          path: 'landings',
          name: 'landings.list',
          component: () => import('@/views/tracker/landings/LandingsList.vue'),
          meta: {
            title: 'Landings',
            parent: 'tracker',
            access: ['hasAccessToLandings'],
          },
        },
        {
          path: 'ad-alerts',
          name: 'ad-alerts.list',
          component: () => import('@/views/tracker/ad-alerts/AdAlertsList.vue'),
          meta: {
            title: 'Ad Alerts',
            parent: 'tracker',
            access: ['hasAccessToAdAlerts'],
          },
        },
        {
          path: 'ab-tests',
          name: 'ab-tests.list',
          component: () => import('@/views/tracker/TrackerList.vue'),
          meta: {
            title: 'A/B tests',
            parent: 'tracker',
          },
        },
        {
          path: 'ab-tests/profile/:hash/:group_id',
          name: 'ab-tests.profile',
          component: () => import('@/views/tracker/ab-tests/AbTestProfile.vue'),
          meta: {
            title: 'A/B test',
            parent: 'tracker',
          },
        },
      ],
    },

    // Tasks
    {
      path: 'tasks',
      name: 'tasks',
      meta: {
        access: ['hasAccessToTasks'],
      },
      children: [
        {
          path: 'new-landing',
          name: 'tasks.new-landing',
          component: () => import('@/views/tasks/NewLandingTask.vue'),
          meta: {
            parent: 'tasks',
            title: 'Landing Page Request',
            access: ['hasAccessToLandingTask'],
          },
        },

        {
          path: 'landing-copy',
          name: 'tasks.landing-copy',
          component: () => import('@/views/tasks/LandingCopyTask.vue'),
          meta: {
            parent: 'tasks',
            title: 'Clone Landing Page Request',
            access: ['hasAccessToLandingCopyTask'],
          },
        },
        {
          path: 'ab-test',
          name: 'tasks.ab-test',
          component: () => import('@/views/tasks/ABTestTask.vue'),
          meta: {
            parent: 'tasks',
            title: 'A/B Test Request',
            access: ['hasAccessToABTestTask'],
          },
        },
        {
          path: 'new-creatives-task',
          name: 'tasks.new-creatives-task',
          component: () => import('@/views/tasks/NewCreativesTask.vue'),
          meta: {
            parent: 'tasks',
            title: 'Creative Request',
            access: ['hasAccessToCreativesTask'],
          },
        },
        {
          path: 'short-task',
          name: 'tasks.new-short-task',
          component: () => import('@/views/tasks/NewShortTask.vue'),
          meta: {
            parent: 'tasks',
            title: 'Short Task Request',
            access: ['hasAccessToShortTask'],
          },
        },
        {
          path: 'new-smm-task',
          name: 'tasks.new-smm-task',
          component: () => import('@/views/tasks/NewSMMTask.vue'),
          meta: {
            parent: 'tasks',
            title: 'Social Media Content Request',
            access: ['hasAccessToSMMTask'],
          },
        },

        {
          path: 'agency-hands-on',
          name: 'tasks.agency-hands-on',
          component: () => import('@/views/tasks/AgencyHandsOnTask.vue'),
          meta: {
            parent: 'tasks',
            title: 'Hands-On Request',
            access: ['hasAccessToHandsOnTask'],
          },
        },

        {
          path: 'other',
          name: 'tasks.other',
          component: () => import('@/views/tasks/OtherTask.vue'),
          meta: {
            parent: 'tasks',
            title: 'Other Request',
          },
        },
        {
          path: 'finance-request',
          name: 'tasks.finance-request',
          component: () => import('@/views/tasks/FinanceRequestTask.vue'),
          meta: {
            parent: 'tasks',
            title: 'Payment Request',
            access: ['hasAccessToPaymentRequestTask'],
          },
        },
      ],
    },

    // Reports
    {
      path: 'reports',
      meta: {
        access: ['hasAccessToReports'],
      },
      children: [
        {
          path: '',
          name: 'reports',
          component: () => import('@/views/reports/ReportsView.vue'),
          meta: {
            title: 'Reports',
          },
        },
      ],
    },

    // Tools
    {
      path: 'tools',
      name: 'tools',
      redirect: {
        name: 'pixels.list',
      },
      meta: {
        title: 'Tools',
        access: ['hasAccessToTools'],
      },
      children: [
        {
          path: 'pixels',
          name: 'pixels.list',
          component: () => import('@/views/tools/ToolsList.vue'),
          meta: {
            title: 'Pixels',
            parent: 'tools',
            access: ['hasAccessToPixels'],
          },
          children: [
            {
              path: 'conversions',
              name: 'conversions.list',
              component: () => import('@/views/tools/ToolsList.vue'),
              meta: {
                title: 'Conversions',
                parent: 'pixels.list',
                access: ['hasAccessToConversions'],
              },
            },
          ],
        },
        // Domains
        {
          path: 'domains',
          name: 'domains.list',
          component: () => import('@/views/tools/ToolsList.vue'),
          meta: {
            parent: 'tools',
            access: ['hasAccessToDomains'],
          },
          beforeEnter: to => {
            to.meta.title = authenticationStore.fromSFCompany ? 'Ads.com Domains' : 'Domains'

            setPageTitle(to)
          },
        },
        {
          path: 'cr-domains',
          name: 'cr-domains.list',
          component: () => import('@/views/tools/ToolsList.vue'),
          meta: {
            title: 'CR Domains',
            parent: 'tools',
            access: ['hasAccessToCRDomain'],
          },
        },
        // Facebook Profiles
        {
          path: 'facebook-profiles',
          name: 'facebook.profiles.list',
          component: () => import('@/views/tools/ToolsList.vue'),
          meta: {
            title: 'Facebook Profiles',
            parent: 'tools',
            access: ['hasAccessToFacebookProfiles'],
          },
        },
        // Links
        {
          path: 'search-feeds-links',
          name: 'search-feeds-links.list',
          component: () => import('@/views/tools/ToolsList.vue'),
          meta: {
            title: 'Links',
            parent: 'tools',
            access: ['hasAccessToSFLinks'],
          },
        },
        // Subscriptions
        {
          path: 'subscriptions',
          name: 'subscriptions.list',
          component: () => import('@/views/tools/ToolsList.vue'),
          meta: {
            title: 'Subscriptions',
            parent: 'tools',
          },
        },
        {
          path: 'email-signature-builder',
          name: 'email-signature-builder',
          component: () => import('@/views/tools/ToolsList.vue'),
          meta: {
            title: 'Email Signature Builder',
            parent: 'tools',
          },
        },
      ],
    },
    // Clickup
    {
      path: 'clickup',
      name: 'clickup.list',
      component: () => import('@/views/clickup/ClickupLibraryList.vue'),
      meta: {
        title: 'ClickUp',
        access: ['hasAccessToClickupPage'],
      },
    },
    // Not Found
    {path: '/:pathMatch(.*)*', name: 'PageNotFound', component: PageNotFound},
  ],
}
