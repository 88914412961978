<script setup>
import {computed, defineProps} from 'vue'

const props = defineProps({
  size: {
    type: String, // small,
    required: false,
  },
  position: {
    type: String, // top, bottom,
    required: false,
    default: 'bottom',
  },
  noMessage: {
    type: Boolean,
    default: false,
  },
  isUnderIcon: {
    type: Boolean,
    default: false,
  },
  xCentered: {
    type: Boolean,
    default: false,
  },
})

const tooltipClass = computed(() => {
  return [
    'the-tooltip',
    props.size === 'small' ? 'small' : props.size === 'w-360' ? 'w-360' : '',
    props.position,
    props.isUnderIcon ? 'under' : '',
    props.xCentered ? 'x-centered' : '',
  ]
})
</script>

<template>
  <div :class="tooltipClass">
    <span class="tooltip-icon">
      <i class="fa-light fa-circle-info"></i>
    </span>

    <span :class="['the-tooltip-message', noMessage ? 'd-none' : '']"><slot /></span>
  </div>
</template>

<style lang="scss" scoped>
.w-360 .the-tooltip-message {
  width: 360px !important;
}
.under .the-tooltip-message {
  top: calc(100% + 7px);
}

.x-centered .the-tooltip-message {
  right: calc(50% - 100px) !important;
}
.x-centered.w-360 .the-tooltip-message {
  right: calc(50% - 180px) !important;
}

.tooltip-icon {
  color: #6b7280;
  font-size: 16px;
}
</style>
