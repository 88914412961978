<script setup>
import {computed} from 'vue'
import {useRoute, useRouter} from 'vue-router'

const route = useRoute()
const router = useRouter()

// Computed
const breadcrumbs = computed(() => {
  const trail = []
  const allRoutes = router.getRoutes() // Cache all routes
  let currentRoute = route

  while (currentRoute) {
    if (currentRoute.meta?.title) {
      trail.unshift({
        title: currentRoute.meta.title,
        path: currentRoute.path,
      })
    }

    const parentName = currentRoute.meta?.parent
    currentRoute = parentName ? allRoutes.find(r => r.name === parentName) : null
  }

  return trail
})
</script>

<template>
  <nav v-if="breadcrumbs.length" aria-label="breadcrumb">
    <ol class="breadcrumb mb-0">
      <li class="breadcrumb-item d-flex align-items-center">
        <router-link :to="{name: 'home'}">
          <h5 class="breadcrumb-title">
            <i class="fas fa-home"></i>
          </h5>
        </router-link>
        <h5 class="breadcrumb-row">/</h5>
      </li>

      <li
        :class="['breadcrumb-item d-flex align-items-center p-0', index >= breadcrumbs.length - 1 ? '' : '']"
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="breadcrumb.path"
      >
        <router-link v-if="index < breadcrumbs.length - 1" :to="breadcrumb.path">
          <h5 class="breadcrumb-title">
            {{ breadcrumb.title }}
          </h5>
        </router-link>
        <h5 v-else class="breadcrumb-item breadcrumb-title active" aria-current="page">
          {{ breadcrumb.title }}
        </h5>
        <h5 v-if="index < breadcrumbs.length - 1" class="breadcrumb-row">/</h5>
      </li>
    </ol>
  </nav>
</template>

<style>
.breadcrumb-item::before {
  display: none;
}

.breadcrumb-item a {
  color: #9c9c9c !important;
  text-decoration: none;
  padding-right: 12px;
}

.breadcrumb-item a:hover {
  text-decoration: underline;
}

.breadcrumb-title {
  color: #9c9c9c;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.42;
  margin: 0;

  &.active {
    font-weight: 700;
    color: #212529 !important;
  }
}

.breadcrumb-row {
  color: #9c9c9c;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.42;
  padding-right: 12px;
  margin: 0;
}
</style>
