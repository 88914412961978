<script setup>
import {defineEmits, defineProps} from 'vue'

defineProps({
  modelValue: {},
  title: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  bold: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['change', 'update:modelValue'])

const onChange = value => {
  emit('update:modelValue', value.target.checked)
  emit('change', value.target.checked)
}
</script>

<template>
  <div class="d-flex align-items-center custom gap-2 base-checkbox">
    <input
      :checked="modelValue"
      class="form-check-input"
      :id="id"
      @change="onChange"
      :disabled="disabled || readonly"
      type="checkbox"
      :required="required"
    />
    <label :class="['pointer', bold ? 'fw-bold' : '']" :for="id">{{ title }}</label>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

.form-check-input {
  cursor: pointer;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  margin-left: 0 !important;
  border: 1px solid #9ca3af;
  margin-top: 0;

  &:checked {
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(235, 112, 75, 0.25) !important;
    }
  }

  &:focus {
    box-shadow: none;
  }

  &.clear-checkbox {
    &:checked {
      background-image: url(@/assets/images/icons/inputs/checkbox-minus.svg);
    }
  }

  &:checked {
    border-color: $primary;
    background-image: url(@/assets/images/icons/inputs/checkbox-checked.svg);
  }
}
</style>
